<template>
    <div class="web-layout">
        <div class="app-board">
            <app-header />
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';

export default {
    name: "MobileLayout",
    components: {
        AppHeader
    }
}
</script>


<style lang="scss" scoped>
    .web-layout {
        width: 100%;
    }
    .app-board {
        width: 100%;
        background: #fff;

        @media(min-width: 512px) {
            width: 320px;
            margin: 0 auto;
        }
    }
</style>
