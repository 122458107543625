<template>
    <div class="web-layout">
        <div class="app-board">
            <app-header />
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';

export default {
    name: "WebLayout",
    components: {
        AppHeader
    }
}
</script>


<style lang="scss" scoped>

</style>
