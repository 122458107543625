import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Notifications from 'vue-notification'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Vuelidate from 'vuelidate'

import App from './App.vue'
import './registerServiceWorker'
import router from './router'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faApple,
  faFacebookF,
  faGithub,
  faGooglePlay,
  faInstagram,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import {faBars, faPaperPlane} from "@fortawesome/free-solid-svg-icons";

/* add each imported icon to the library */
library.add(
    faFacebookF,
    faBars,
    faGithub,
    faLinkedin,
    faInstagram,
    faApple,
    faGooglePlay,
    faPaperPlane
)


// styles
// import "@/assets/scss/styles.scss"

Vue.config.productionTip = false

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Notifications)
Vue.use(VueLoading)
Vue.use(Vuelidate)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
