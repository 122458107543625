import Vue from 'vue'
import VueRouter from 'vue-router'
import WebView from '../layout/WebView.vue'
import MobileView from "@/layout/MobileView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home')
  },
  {
    path: '/',
    name: 'RevelMobile',
    component: MobileView,
    children: [
      {
        path: '/qr/:eventQrCode',
        name: 'Event',
        component: () => import("@/views/EventView.vue")
      },
      {
        path: '/order-detail',
        name: 'OrderDetail',
        component: () => import("@/views/OrderDetail.vue")
      },
    ]
  },
  {
    path: '/',
    name: 'RevelWeb',
    component: WebView,
    children: [
      {
        path: '/app-policy',
        name: 'AppPolicy',
        component: () => import("@/views/AppPolicy.vue")
      },
      {
        path: '/download',
        name: 'AppDownload',
        component: () => import("@/views/AppDownload.vue")
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
