<template>
    <div class="svg-image">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "SvgImage"
}
</script>
<style lang="scss" scoped>
.svg-image {
    width: max-content;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>